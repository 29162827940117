import Vue from 'vue'
import VueRouter from 'vue-router'

import MakeMoney from './make-money'
import Main from './main'

Vue.use(VueRouter)

const routeArray = [
    {
        name: '/',
        path: "/",
        component: () => import('../views/index'),
        meta: {
            name: '首页'
        }
    },
    {
        name: 'nav',
        path: "/nav",
        component: () => import('../views/nav'),
        meta: {
            name: '导航',
            ignore: true
        }
    },
    {
        name: 'main',
        path: "/main",
        component: () => import('../views/main/index'),
        meta: {
            name: '修仙内容'
        },
        redirect: '/main/common',
        children: Main
    },
    {
        name: 'mod',
        path: "/mod",
        component: () => import('../views/mod/index'),
        meta: {
            name: '多彩助手'
        }
    },
    {
        name: 'newbie',
        path: "/newbie",
        component: () => import('../views/newbie/index'),
        meta: {
            name: '新手攻略'
        },
        children: [
            {
                name: 'newbie',
                path: "/newbie/start",
                component: () => import('../views/newbie/start'),
                meta: {
                    name: '新手开局'
                }
            },
            {
                name: 'question',
                path: "/newbie/question",
                component: () => import('../views/newbie/question'),
                meta: {
                    name: '新手常见问题'
                }
            }
        ]
    },
    {
        name: 'makeMoney',
        path: "/makeMoney",
        component: () => import('../views/makeMoney/index'),
        meta: {
            name: '获取猫仙币'
        },
        redirect: '/makeMoney/topUp',
        children: MakeMoney
    },
    {
        name: 'file',
        path: "/file",
        component: () => import('../views/file/index'),
        meta: {
            name: '其它文档'
        }
    },
];

const router = new VueRouter({
    routes: routeArray
});

const routes = [];

function copy(route) {
    let one = {
        name: route.meta ? route.meta.name : '未命名',
        show: false,
        routeName: route.name,
        children: []
    }
    if (route.children) {
        for (let child of route.children) {
            one.children.push(copy(child));
        }
    }
    return one;
}

for (let route of routeArray) {
    if (route.meta && route.meta.ignore) {
        continue;
    }
    routes.push(copy(route));
}

export {
    routes
};

export default router;