<template>
    <div class="footer">
        <div class="container">
            <div class="qun">
                <p>游戏内容：升级修仙、休闲建家、挣钱养家、神器交易、挖矿种菜、抽奖暴富、秘境组队、宗门竞技。</p>
                <p>活动内容：生存冠军、捕鱼达人、战神竞速、建家大赛、种瓜比赛。</p>
                <p>打怪升级、种菜建家，一直被模仿，从未被超越。你想要的玩法这里全都有。</p>
                <p>进服姿势：联机大厅搜索【多彩】，你没玩过的全新版本！</p>
                <p>你要战便战，我在多彩等你！</p>
            </div>
            <div class="qq">
                <div class="qr">
                    <img src="../assets/images/duocai/QR_Steam.png" alt="">
                    <div>ST群：<a href="https://jq.qq.com/?_wv=1027&k=0SpnwENa" target="_blank">692521567</a></div>
                </div>
                <div class="qr">
                    <img src="../assets/images/duocai/QR_WeGame.png" alt="">
                    <div>WG群：<a href="https://jq.qq.com/?_wv=1027&k=7LKmT4QB" target="_blank">377696084</a></div>
                </div>
            </div>
            <div class="copyright">
                2023 &copy; by 华山宁不凡&白起43 <a href="http://beian.miit.gov.cn" target="_blank">湘ICP备2023007980号</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Footer"
}
</script>

<style lang="scss" scoped>

.footer {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    background-color: rgba(240, 102, 22, 0.7);
    padding: 50px 0;
    font-size: 14px;
    color: rgba(240, 240, 240, 0.9);
    margin-top: 40px;

    .container {
        display: flex;
        flex-wrap: wrap;
    }

    .qq {
        display: flex;
        align-items: center;
        justify-content: center;

        .qr {
            text-align: center;
            font-size: 12px;
            line-height: 1.5;

            img {
                display: block;
                width: 128px;
                height: 128px;
                margin: 0 20px;
            }

            a {
                color: rgba(240, 240, 240, 0.9);
            }
        }

    }

    .qun {

        padding-bottom: 20px;

        p {
            font-size: 14px;
            color: rgba(240, 240, 240, 0.9);
        }

    }

    .copyright {
        width: 100%;
        padding-top: 28px;
        font-size: 12px;
        line-height: 12px;
        text-align: center;

        a {
            color: rgba(240, 240, 240, 0.7);
        }

    }

}


</style>